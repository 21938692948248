import React, { Component } from 'react';
import GroupImage from "../images/group-img.png";
import StratImage from "../images/strat-img.png";
import CommImage from "../images/comm-img.png";
import NetImage from "../images/net-img.png";
import "../styles/Info.css";

export class Info extends Component {
    render() {
        return (
            <div className="info-container">
                <div className="info-left group-inv">
                    <div className="info-image-container">
                        <img className="info-image" src={GroupImage} alt="placeholder" />
                    </div>
                    <div className="info-text-container">
                        <h2 style={{color: "#efc7ff"}}>Group Investing With A Say In Every Investment</h2>
                        <p>&emsp;Our Investors put up a certain amount of capital which will reflect in each investors dashboard once the funds are received, shown as investable funds.</p>
                        <p>&emsp;We will then pool together a minimum of four investors, per investment vehicle, to minimize the risk for each investor involved.</p>
                        <p>&emsp;You as an investor will not be required to invest in any of the deals we present if you choose not to!</p>
                        <p>&emsp;When a deal comes across that presents potential for growth we will send this deal over to our panel of investors as well as our vetting department for review. Depending on the investment; the turnaround time is generally 24-78 hours. The vetting department will take the potential investment and research the industry, the location, the demand, the current owners as well as the potential for growth in the near and distant future. After careful analysis they then submit a vote.</p>
                    </div>
                </div>
                <div className="info-right strat-inv">
                    <div className="info-image-container">
                        <img className="info-image" src={StratImage} alt="placeholder" />
                    </div>
                    <div className="info-text-container">
                        <h2 style={{color: "#efc7ff"}}>Strategic Investment Vehicles</h2>
                        <p>&emsp;There will be three main categories of investment vehicles that will be presented to you when in this group. They consist of the overlapping market of real estate, stock and crypto currency.</p>
                        <p>&emsp;Our presented investment vehicles aim to diversify the portfolio of each investor; diversify the risk to reward statute each investor has in place and get involved together as a group.</p>
                    </div>
                </div>
                <div className="info-left clear-com">
                    <div className="info-image-container">
                        <img className="info-image" src={CommImage} alt="placeholder" />
                    </div>
                    <div className="info-text-container">
                        <h2 style={{color: "#efc7ff"}}>Clear Communication</h2>
                        <p>&emsp;This group prides itself in response time for each member involved. When a message is sent the whole team will receive this notification directly to their mobile device as well as their email!</p>
                        <p>&emsp;We want to take the stress, worry and guesswork out of the investing world to provide each investor either a hands off approach, or a learning experience to take home.</p>
                        <p>&emsp;We all want to do more and make more! As a team there is no better way than to do so with active communication!</p>
                    </div>
                </div>
                <div className="info-right access-net">
                    <div className="info-image-container">
                        <img className="info-image" src={NetImage} alt="placeholder" />
                    </div>
                    <div className="info-text-container">
                        <h2 style={{color: "#efc7ff"}}>Access to A Network of Investors, Resources for Funding & Growth</h2>
                        <p>&emsp;Cryo Capital was started by investors for investors both big and small. Our experience and network as an organization; is yours just as it is ours with full transparency. Our only request is that if you believe you may have a way to provide value to the group; that you do actually take time to present it to the group for review and vote</p>
                        <p>&emsp;From hard money to mortgage lending to credit repair, to stock analysis to broker/agent relationships we have built a network collectively with a purpose of investing.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Info
