import React, { Component } from 'react';
import "../styles/Footer.css";

class Footer extends Component {
    render() {
        return (
            <div>
                <div className="footer-container">
                    <p>Cryo Capital LLC © 2021</p>
                </div>
            </div>
        )
    }
}

export default Footer;
