import React, { Component } from 'react';
import "../styles/Form.css";
import emailjs from 'emailjs-com';

class Form extends Component {
    state = {
        name: "",
        email: "",
        btnClass: "submit disabled",
        btnDisabled: true,
        btnText: "Submit",
        templateParams: {},
        inputDisabled: false,
        inputClass: "input"
    }
    
    sendEmail(e) {
        e.preventDefault();
        this.setState({
            btnClass: "submit in-progress",
            btnDisabled: true,
            btnText: "Sending..."
        })
        emailjs.send('service_9ogxuwr', 'template_yfws69a', this.state.templateParams, 'T2k5m4O9Fn64ApoKD')
        .then((result) => {
            console.log(result.text, result);
            this.setState({
                name: "",
                email: "",
                btnClass: "submit disabled",
                btnDisabled: true,
                templateParams: {}
            },() => {
                if (result.status === 200) {
                    this.setState({
                        btnClass: "submit submit-ok",
                        btnDisabled: true,
                        btnText: "Submitted! Thank you!",
                        inputDisabled: true,
                        inputClass: "input input-disabled"
                    })
                }
            })
        }, (error) => {
            console.log(error.text);
        });
    };
    
    handleChange(e) {
        e.preventDefault();

        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            if (this.state.name.length >= 2 && this.state.email.length >= 5) {
                this.setState({
                    btnClass: "submit",
                    btnDisabled: false,
                    templateParams: {
                        name: `${this.state.name}`,
                        email: `${this.state.email}`
                    }
                })
            } else {
                this.setState({
                    btnClass: "submit disabled",
                    btnDisabled: true,
                    templateParams: {
                        name: `${this.state.name}`,
                        email: `${this.state.email}`
                    }
                })
            }
        })
    }

    scrollToInfo(e) {
        e.preventDefault();
        window.scrollTo(0, 920);
    }
    
    
    render() {
        return (
            <div className="form-container">
                <div className="form-text">
                    <p style={{maxWidth: "600px"}}>&emsp;Please enter your name and email address below to join our mailing list. We look forward to hearing from you!</p>
                </div>
                <div className="form-inputs">
                    <form onSubmit={e => this.sendEmail(e)}>
                        <input 
                            disabled={this.state.inputDisabled}
                            className={this.state.inputClass}
                            type="text"
                            name="name"
                            onChange={e => this.handleChange(e)}
                            value={this.state.name}
                            placeholder="Name"
                        />
                        <input
                            disabled={this.state.inputDisabled}
                            className={this.state.inputClass}
                            type="email"
                            name="email"
                            onChange={e => this.handleChange(e)}
                            value={this.state.email}
                            placeholder="Email"
                        />
                        <input
                            disabled={this.state.btnDisabled}
                            className={this.state.btnClass}
                            type="submit"
                            value={this.state.btnText}
                        />
                    </form>
                </div>
                <button className="about-button" onClick={e => this.scrollToInfo(e)}>Read More About Us!</button>
            </div>
        );
    }
}

export default Form;