import React, { Component } from 'react'
import CryoLogoWhite from '../images/cryo-white-shadow.png';
import Underline from '../images/underline.png';
import "../styles/Logo.css";

class Logo extends Component {
    render() {
        return (
            <div className="logo-container">
                <div className="logo">
                    <img src={CryoLogoWhite} alt="Cryo Capital Investment Group"/>
                    <img src={Underline} alt="----------------- CC -----------------"/>
                </div>
            </div>
        )
    }
}

export default Logo

