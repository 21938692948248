import React, { Component } from 'react';
import "../styles/DesktopForm.css";
import emailjs from 'emailjs-com';

class DesktopForm extends Component {
    state = {
        name: "",
        email: "",
        btnClass: "d-submit d-disabled",
        btnDisabled: true,
        btnText: "Submit",
        templateParams: {},
        inputDisabled: false,
        inputClass: "d-form-input"
    }
    
    sendEmail(e) {
        e.preventDefault();
        this.setState({
            btnClass: "d-submit in-progress",
            btnDisabled: true,
            btnText: "Sending..."
        })
        emailjs.send('service_9ogxuwr', 'template_yfws69a', this.state.templateParams, 'T2k5m4O9Fn64ApoKD')
        .then((result) => {
            console.log(result.text, result);
            this.setState({
                name: "",
                email: "",
                btnClass: "d-submit disabled",
                btnDisabled: true,
                templateParams: {}
            },() => {
                if (result.status === 200) {
                    this.setState({
                        btnClass: "d-submit d-submit-ok",
                        btnDisabled: true,
                        btnText: "Submitted! Thank you!",
                        inputDisabled: true,
                        inputClass: "d-form-input d-form-input-disabled"
                    })
                }
            })
        }, (error) => {
            console.log(error.text);
        });
    };
    
    handleChange(e) {
        e.preventDefault();

        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            if (this.state.name.length >= 2 && this.state.email.length >= 5) {
                this.setState({
                    btnClass: "d-submit",
                    btnDisabled: false,
                    templateParams: {
                        name: `${this.state.name}`,
                        email: `${this.state.email}`
                    }
                })
            } else {
                this.setState({
                    btnClass: "d-submit d-disabled",
                    btnDisabled: true,
                    templateParams: {
                        name: `${this.state.name}`,
                        email: `${this.state.email}`
                    }
                })
            }
        })
    }

    scrollToInfo(e) {
        e.preventDefault();
        window.scrollTo(0, 920);
    }

    render() {
        return (
            <div className="desktop-container">
                <div className="d-about-container">
                    <span><h1>O</h1><h2>UR</h2> <h1>M</h1><h2>ISSION</h2></span>
                    <p>&emsp;This group was founded by self-earning investors with a goal to simplify the investing process for others through clear communication. Step by step, throughout each investment strategy, learn and have a voice in each and every decision. You will maximize your return through the pooling of capital with diversified risk management. We believe that people are strong in large groups, and working efficiently as a team will create an investment powerhouse that will compete with the biggest firms in the industry. With a team of intensive, well qualified and highly accessible individuals, rest-assured you joined the right group.</p>
                </div>
                <div className="d-form-container">
                    <div className="d-form-text">
                        <p style={{maxWidth: "600px"}}>Please enter your name and email address below to join our mailing list. We look forward to hearing from you!</p>
                    </div>
                    <div className="d-form-inputs">
                        <form onSubmit={e => this.sendEmail(e)}>
                            <input 
                                disabled={this.state.inputDisabled}
                                className={this.state.inputClass}
                                type="text"
                                name="name"
                                onChange={e => this.handleChange(e)}
                                value={this.state.name}
                                placeholder="Name"
                            />
                            <input
                                disabled={this.state.inputDisabled}
                                className={this.state.inputClass}
                                type="email"
                                name="email"
                                onChange={e => this.handleChange(e)}
                                value={this.state.email}
                                placeholder="Email"
                            />
                            <input
                                disabled={this.state.btnDisabled}
                                className={this.state.btnClass}
                                type="submit"
                                value={this.state.btnText}
                            />
                        </form>
                    </div>
                    <button className="d-about-button" onClick={e => this.scrollToInfo(e)}>Read More About Us!</button>
                </div>
            </div>
        )
    }
}

export default DesktopForm
