import React, { Component } from 'react'
import './App.css';
import About from './components/About';
import BackToTop from './components/BackToTop';
import DesktopForm from './components/DesktopForm';
import Footer from './components/Footer';
import Form from './components/Form';
import Info from './components/Info';
import Logo from "./components/Logo";
import NavLogo from './components/NavLogo';

class App extends Component {
  state = {
    currScroll: 0,
  }
  
  handleScroll(e) {
    e.preventDefault();
    const header = document.querySelector(".logo-container");
    const body = document.querySelector("body");
    const bodyTop = body.getBoundingClientRect().top;
    
    if (header !== null) {
      if (bodyTop <= 1200) {
        header.classList.remove("nothing");
      } else {
        header.classList.add("nothing");
      }
      this.setState({
        currScroll: bodyTop
      })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', (e) => this.handleScroll(e));
  }

  render() {
    return (
      <div className="App">
        <Logo />
        <NavLogo scroll={this.state.currScroll}/>
        <DesktopForm />
        <About />
        <Form />
        <Info />
        <BackToTop scroll={this.state.currScroll}/>
        <Footer />
      </div>
    )
  }
}

export default App;
