import "../styles/About.css";
import React, { Component } from 'react'

class About extends Component {
    render() {
        return (
            <div className="about-container">
                <span><h1>O</h1><h2>UR</h2> <h1>M</h1><h2>ISSION</h2></span>
                <p>&emsp;This group was founded by self-earning investors with a goal to simplify the investing process for others through clear communication. Step by step, throughout each investment strategy, learn and have a voice in each and every decision. You will maximize your return through the pooling of capital with diversified risk management. We believe that people are strong in large groups, and working efficiently as a team will create an investment powerhouse that will compete with the biggest firms in the industry. With a team of intensive, well qualified and highly accessible individuals, rest-assured you joined the right group.</p>
            </div>
        )
    }
}

export default About;

