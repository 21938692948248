import React, { Component } from 'react';
import '../styles/BackToTop.css';
import { UpArrow } from './Svg';

class BackToTop extends Component {
    state = {
        btnClass: this.getButtonClass(),
    }

    getButtonClass(name) {
        if (this.props.scroll >= -150) {
            switch (name) {
                case "up":
                    return "back-to-top hidden";
                case "in":
                    return "linked-in hidden";
                default:
                    return "back-to-top hidden";
            }
        } else {
            switch (name) {
                case "up":
                    return "back-to-top";
                case "in":
                    return "linked-in";
                default:
                    return "back-to-top";
            }
        }
    }

    scrollToTop(e) {
        e.preventDefault();
        window.scrollTo(0, 0);
    }

    render() {
        return (
            // <div className="back-to-top-container" style={{position: "fixed", bottom: ((this.props.scroll <= -2530) ? `${((2490 + this.props.scroll) * -1)}px` : `${75}px`)}}>
            //     <button disabled={this.props.scroll >= -150} className={this.getButtonClass()} onClick={e => this.scrollToTop(e)}><UpArrow /></button>
            // </div>
            <div className="buttons-container" style={{position: "fixed", bottom: `${40}px`}}>
                {/* <button disabled={this.props.scroll >= -150} className={this.getButtonClass("in")} onClick={e => this.scrollToTop(e)}><LinkedIn /></button> */}
                <button disabled={this.props.scroll >= -150} className={this.getButtonClass("up")} onClick={e => this.scrollToTop(e)}><UpArrow /></button>
            </div>
            )
    }
}

export default BackToTop
