import React, { Component } from 'react';
import CryoLogoWhite from '../images/cryo-white-shadow.png';
import Underline from '../images/underline.png';
import "../styles/NavLogo.css";

class NavLogo extends Component {
    state = {
        navClass: this.getNavClass()
    }

    getNavClass() {
        if (this.props.scroll <= -900) {
            return "nav-logo-container scrolled"
        } else {
            return "nav-logo-container hidden"
        }
    }

    render() {
        return (
            <div className={this.getNavClass()}>
                <div className="nav-logo-image">
                    <img className="nav-logo-cryo" src={CryoLogoWhite} alt="Cryo Capital Investment Group"/>
                    <img className="nav-logo-under" src={Underline} alt="----------------- CC -----------------"/>
                </div>
            </div>
        )
    }
}

export default NavLogo
